import * as React from 'react';
import autobind from 'autobind-decorator';
import * as moment from 'moment';
import { Dispatch, connect } from 'react-redux';
import { SuggestItemParams } from 'sber-marketing-ui';
import { OrganizationParams, UserParams, CreateUserParams } from 'sber-marketing-types/admin';

import { UpdateOrganizationParams } from '../../../../store/organizations/types';
import { AccountStatus } from 'sber-marketing-types/openid';

import { CreateForm, CreateFormParams } from './CreateForm';
import { State as StoreState } from '../../../../store';
import { getOrganizations, getCurrentOrganization } from '../../../../store/organizations/selectors';
import { getBusinessAdmin } from '../../../../store/user/selectors';
import { addUser, updateUser } from '../../../../store/user/actions';
import {
    updateCurrentOrganization,
    resetCurrentOrganization,
    updateOrganization,
} from '../../../../store/organizations/actions';
import { UserApi, OrganizationApi } from '../../../../api';

interface Props extends CreateFormParams, MapProps, DispatchProps {
    executorIds: string[];
    customerIds: string[];
    isBlocked?: boolean;
    onConfirmClick: () => Promise<{
        organizationId: string;
        organizationName: string;
    }>;
    businessAdmin?: Partial<UserParams>;
}

interface State {
    userCreating: boolean;
    businessAdmin: Partial<UserParams>;
}

interface MapProps {
    organizations?: OrganizationParams[];
    organization?: OrganizationParams;
    getAdmin?: (id: string) => UserParams;
}

interface DispatchProps {
    updateCurrentOrganization?: (params: UpdateOrganizationParams) => void;
    resetCurrentOrganization?: () => void;
    updateOrganization?: (organization: UpdateOrganizationParams) => void;
    addUser?: (user: UserParams) => void;
    updateUser?: (user: UserParams) => void;
}

const clearBusinessAdmin = {
    id: 0,
    firstName: '',
    middleName: '',
    secondName: '',
    phoneNumber: '',
    email: '',
    activeUntil: moment().add(1, 'year').format(),
    status: AccountStatus.ACTIVE,
};

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class OrganizationFormContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            userCreating: false,
            businessAdmin: clearBusinessAdmin,
        };
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const admin = this.props.getAdmin(this.props.organization.id);

        if (prevState.businessAdmin == clearBusinessAdmin && admin) {
            this.setState({
                businessAdmin: admin,
            });
        }
    }

    public render(): JSX.Element {
        const {
            blockTitle,
            confirmButtonText,
            name,
            organization,
            organizations,
            customerIds,
            executorIds,
            isBlocked,
        } = this.props;

        const suggestItems = organizations.map((item) => this.mapOrganizationToSuggestParams(item));
        const customersSuggestItems = customerIds
            .map((id) => organizations.find((item) => item.id == id))
            .map((item) => this.mapOrganizationToSuggestParams(item));
        const executorsSuggestItems = executorIds
            .map((id) => organizations.find((item) => item.id == id))
            .map((item) => this.mapOrganizationToSuggestParams(item));

        return (
            <CreateForm
                organizationId={organization?.id}
                blockTitle={blockTitle}
                confirmButtonText={confirmButtonText}
                nameInputHeading={'Название компании'}
                name={name}
                businessAdmin={this.state.businessAdmin}
                onChangeBusinessAdminField={this.handleChangeBusinessAdminField}
                userCreating={this.state.userCreating}
                toggleUserCreating={this.toggleUserCreating}
                suggestItems={suggestItems}
                leftSuggestHeading={'Заказчики'}
                leftSuggestItems={customersSuggestItems}
                onLeftSuggestSelect={this.handleCustomerSelect}
                rightSuggestHeading={'Исполнители'}
                rightSuggestItems={executorsSuggestItems}
                isBlocked={isBlocked}
                onRightSuggestSelect={this.handleExecutorSelect}
                onNameChange={this.onNameChange}
                onConfirmClick={this.handleConfirmClick}
                onCancelClick={this.props.resetCurrentOrganization}
            />
        );
    }

    @autobind
    protected handleExecutorSelect(item: SuggestItemParams) {
        const { executorIds } = this.props;

        const isChecked = !executorIds.find((id) => id == item.value);

        const updatedExecutorIds = isChecked
            ? [...executorIds, String(item.value)]
            : executorIds.filter((id) => id != item.value);

        this.props.updateCurrentOrganization({
            executorIds: updatedExecutorIds,
        });
    }

    @autobind
    protected async handleConfirmClick() {
        const { organizationId, organizationName } = await this.props.onConfirmClick();

        if (this.state.businessAdmin.id == 0) {
            if (
                this.state.businessAdmin.email &&
                this.state.businessAdmin.firstName &&
                this.state.businessAdmin.secondName
            ) {
                await this.saveUser(organizationId, organizationName);
            }
        } else {
            await this.updateUser(organizationId);
        }
    }

    @autobind
    protected handleChangeBusinessAdminField(field: string, value: string) {
        this.setState((prevState) => ({
            businessAdmin: {
                ...prevState.businessAdmin,
                [field]: value,
            },
        }));
    }

    @autobind
    protected handleCustomerSelect(item: SuggestItemParams) {
        const { customerIds } = this.props;

        const isChecked = !customerIds.find((id) => id == item.value);

        const updatedCustomerIds = isChecked
            ? [...customerIds, String(item.value)]
            : customerIds.filter((id) => id != item.value);

        this.props.updateCurrentOrganization({
            customerIds: updatedCustomerIds,
        });
    }

    @autobind
    protected onNameChange(name: string) {
        this.props.updateCurrentOrganization({
            name,
        });
    }

    @autobind
    protected toggleUserCreating() {
        this.setState((prevState: State) => ({
            userCreating: !prevState.userCreating,
        }));
    }

    private async saveUser(organizationId: string, organizationName: string) {
        const userParams = this.getUserParams(organizationId) as CreateUserParams;

        const user = await UserApi.createUser(userParams);
        const departmentId = await OrganizationApi.getDepartmentByOrganizationId(organizationId, organizationName);
        await UserApi.setDepartmentRole(user.id, departmentId, 3);
        this.props.addUser({ ...user, roles: [{ id: 3, name: 'Бизнес администратор' }] });
    }

    private async updateUser(organizationId: string) {
        const userParams = this.getUserParams(organizationId) as UserParams;

        await UserApi.updateUser(userParams);
        this.props.updateUser({ ...userParams, roles: [{ id: 3, name: 'Бизнес администратор' }] });
    }

    private getUserParams(organizationId: string): CreateUserParams | UserParams {
        const { activeUntil, firstName, secondName, middleName, email, phoneNumber, status, id } =
            this.state.businessAdmin;

        const userParams = {
            id,
            activeUntil,
            firstName,
            secondName,
            middleName,
            email,
            phoneNumber,
            status,
            organizationId,
        };

        return userParams;
    }

    private mapOrganizationToSuggestParams(organization: OrganizationParams): SuggestItemParams {
        return {
            value: organization.id,
            label: organization.name,
        };
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        organizations: getOrganizations(state),
        organization: getCurrentOrganization(state),
        getAdmin: (id: string) => getBusinessAdmin(state, id),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        updateCurrentOrganization: (params: UpdateOrganizationParams) => dispatch(updateCurrentOrganization(params)),
        resetCurrentOrganization: () => dispatch(resetCurrentOrganization()),
        updateOrganization: (organization: UpdateOrganizationParams) => dispatch(updateOrganization(organization)),
        addUser: (user: UserParams) => dispatch(addUser(user)),
        updateUser: (user: UserParams) => dispatch(updateUser(user)),
    };
}
